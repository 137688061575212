import React from 'react'
import { Link } from 'gatsby'
import v4 from 'uuid/v4'

const listStyle = {
	badge: {
		list: "list-badge list-inline",
		item: "list-badge-item list-inline-item",
		link: "list-badge-link badge badge-secondary p-3 mb-md-3 mr-3"
	},
	menu: {
		list: "list-menu list-unstyled",
		item: "list-menu-item",
		link: "list-menu-link"
	},
	list:{
		list: "list-list list-unstyled",
		item: "list-list-item mb-3",
		link: "list-list-link"
	}
}

const Links = ({attrs}) =>
	<div className="mb-5 ">
		<h2>{attrs.title}</h2>
		<ul className={listStyle[attrs.displayMode].list}>
			{attrs.items && attrs.items.map(({title, full_url, slug, blank}) =>
				<li key={v4()} className={listStyle[attrs.displayMode].item}>
					{slug ?
						<Link className={listStyle[attrs.displayMode].link} target={(blank ? "_blank" : "_self")} rel={(blank ? "noreferrer" : "")} to={slug}>{title}</Link> :
						<a className={listStyle[attrs.displayMode].link} target={(blank ? "_blank" : "_self")} rel={(blank ? "noreferrer" : "")} href={(full_url ? full_url : '#')}>{title}</a>
					}
				</li>
			)}
		</ul>
	</div>

export default Links
