import React from 'react'
import Img from 'gatsby-image'
import Slider from "react-slick"
import v4 from 'uuid/v4'

const settings = {
	dots: false,
	infinite: false
}

const Galery = ({attrs, images}) =>
	<div className="carousel slideshow">
		<Slider {...settings}>
			{attrs.items && attrs.items.map(({imageid, legend, link}) => {
				const img = imageid && imageid !== 0 && images && images.find(({wordpress_id}) => wordpress_id === imageid)
				const imageSharpSizes = img && img.localFile && img.localFile.childImageSharp && img.localFile.childImageSharp.sizes

				const el = () => {
					return(
						<>
							<Img sizes={imageSharpSizes} alt=""/>
							<div className="slide-bottom">
								<p>{legend}</p>
							</div>
						</>
					)
				}
       	return imageSharpSizes &&
        	<div key={v4()} className={`carousel-slide`}>
						{link ? <a title="Cliquez pour ouvrir le lien dans un nouvel onglet" href={link} target="_blank" rel="noopener noreferrer">{el()}</a> : el() }
					</div>
	    })}
        </Slider>
	</div>

export default Galery


// import React, { Component } from 'react'
// import Img from 'gatsby-image'
// import { Container } from 'reactstrap'
// import Slider from "react-slick";


// class Galery extends Component {
// 	constructor(props, context) {
// 		super(props, context);

// 		this.state = {
// 			theater: false,
// 			activeItem: null
// 		}

// 		this.handleSlideCLick = this.handleSlideCLick.bind(this)
// 		this.handleCarouselClick = this.handleCarouselClick.bind(this)
// 	}

// 	handleSlideCLick(key){
// 		this.setState({
// 			theater: true,
// 			activeItem: key
// 		})
// 	}

// 	handleCarouselClick(){
// 		if(this.state.theater){
// 			this.setState({
// 				activeItem: null,
// 				theater: false
// 			})
// 		}
// 	}

// 	render(){
// 		const { items } = this.props.attrs

// 		const settings = {
// 	    	dots: false,
// 	    	infinite: false
// 	    }

// 	    let theaterRender = null

// 	    if(this.state.theater && this.state.activeItem !== null){
// 	    	const item = items[this.state.activeItem]
// 	   		const img = item.imageid && item.imageid !== 0 && this.props.images && this.props.images.find(({wordpress_id}) => wordpress_id === item.imageid)
// 	    	theaterRender =
// 	    		<div className="carousel-theater">
// 	    			<div className="content-wrapper">
// 	    				<Container>
// 	    					<div className="content">
// 			    				<Img sizes={img.localFile.childImageSharp.sizes}/>
// 		    				</div>
// 	    				</Container>
// 	    			</div>
// 	    		</div>
// 	    }

// 		return(
// 			<>
// 			{theaterRender}
// 			<div className="carousel slideshow">
// 				<Slider {...settings}>
// 				{
// 		         	items.map((i, key) => {
// 		         		const img = i.imageid && i.imageid !== 0 && this.props.images && this.props.images.find(({wordpress_id}) => wordpress_id === i.imageid)
// 		         		if(img && img.localFile.childImageSharp && img.localFile.childImageSharp.sizes){
// 		         			return(
// 			         			<div key={key} className={`carousel-slide ${(this.state.theater && this.state.activeItem && this.state.activeItem === key ? "full-size" : "")}`}>
// 									<Img sizes={img.localFile.childImageSharp.sizes}/>
// 									<div className="slide-bottom">
// 										<p>{i.legend}</p>
// 									</div>
// 								</div>
// 		         			)
// 		         		}else return null
// 		         	})
// 		        }
// 		        </Slider>

// 			</div>
// 			</>
// 		)
// 	}
// }

// export default Galery;
