import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { isValidUrl } from "./../../../utils/functions";

const HomeTile = ({
  imageid,
  link,
  title,
  keywords,
  images,
  placeholder,
  blank
}) => {
  const img =
    imageid &&
    imageid !== 0 &&
    images &&
    images.find(({ wordpress_id }) => wordpress_id === imageid);
  const imageSharpSizes =
    img &&
    img.localFile &&
    img.localFile.childImageSharp &&
    img.localFile.childImageSharp.sizes;

  if (placeholder) {
    return (
      <div className="tiles-list-item">
        <div className="placeholder">
          <div className="img-wrapper" />
          <div className="h3" />
          <span />
        </div>
      </div>
    );
  }

  if (blank || isValidUrl(link)) {
    return (
      <div className="tiles-list-item">
        <a href={link} target="_blank" rel="noopener noreferrer">
          <div className="img-wrapper">
            {imageSharpSizes && <Img sizes={imageSharpSizes} />}
          </div>
          <h3>{title}</h3>
          <span>{keywords}</span>
        </a>
      </div>
    );
  }

  return (
    <div className="tiles-list-item">
      <Link to={link}>
        <div className="img-wrapper">
          {imageSharpSizes && <Img sizes={imageSharpSizes} />}
        </div>
        <h3>{title}</h3>
        <span>{keywords}</span>
      </Link>
    </div>
  );
};

export default HomeTile;
