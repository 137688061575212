import React from 'react'
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'
import {Label} from 'reactstrap'
import Select from 'react-select'
import arrow from '../../../../../img/icons/arrow-pink-right.svg'

class SelectField extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            value: ""
        }
    }

    onChange = (value) => {
        this.setState({value})
    }

    render(){
        const {value} = this.state
        const {size, field} = this.props
        let options = [{value: '', label: '-- Selectionner --'}]
        field.choices.map(({value, text}) => { 
            const option = {value: value, label: text}
            options.push(option)
            return null
         })
         
        return(
            <AvGroup className={`${size} ${field.cssClass}`}>
                <Label for={field.gravityId}>{field.label + (field.isRequired ? ' *' : '')}</Label>
                <Select 
                    className="custom-form-select"
                    searchable={false}
                    clearable={false}
                    simpleValue
                    value={(this.state && this.state.value ? this.state.value : options[0])}
                    onChange={this.onChange}
                    options={options} 
                    arrowRenderer={() => <img className="select-arrow" alt="" src={arrow}/>}
                />
                <AvInput 
                    name={field.gravityId} 
                    id={field.gravityId} 
                    required={field.isRequired} 
                    type="text"
                    value={value}
                    className="d-none"
                />
                <AvFeedback>{field.errorMessage !== "" ? field.errorMessage : "Champs non valide"}</AvFeedback>
               {field.description && <p className="field-description mt-1 mb-0">{field.description}</p>}
           </AvGroup>
       )
    }
}

export default SelectField

