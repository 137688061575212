import { connect } from "react-redux";

import { getCookiebox } from "../../state/reducers/cookiebox";
import { iframeParse } from "../../utils/functions";

import Core from "./Core";
import Cgb from "./Cgb";

const switchBlocks = {
  core: Core,
  cgb: Cgb
};

const Blocks = ({ blockName, cookiebox, innerHTML, ...rest }) => {
  const blockSplit = blockName && blockName.split("/")[0];
  const blockHTML = cookiebox ? iframeParse(innerHTML) : innerHTML;
  return (
    blockSplit &&
    typeof switchBlocks[blockSplit] === "function" &&
    switchBlocks[blockSplit]({
      blockName,
      innerHTML: blockHTML,
      ...rest
    })
  );
};

const mapStateToProps = state => ({
  cookiebox: getCookiebox(state)
});

export default connect(mapStateToProps)(Blocks);
