import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";

import * as actions from "../../../state/actions/cookiebox";
import { getCookiebox } from "../../../state/reducers/cookiebox";
import LanguageContext from "../../../state/context/LanguageContext";

const ManageCookie = ({ cookiebox = true, closeCookiebox, showCookiebox }) => {
  return (
    <LanguageContext.Consumer>
      {({ language, translation }) => (
        <div className="block-manage-cookies my-5">
          <Row>
            <Col>
              <h3 id="cookies">{translation.cookieManageTitle}</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="manage-cookies-option mb-4">
                <label className="switch">
                  <span className="switch-text">Cookies</span>
                  <input
                    type="checkbox"
                    checked={ ( cookiebox === false && cookiebox !== 'refused' ) ? "checked" : ""}
                    onClick={() => {
                      if (cookiebox !== true) {
                        showCookiebox();
                      } else {
                        closeCookiebox();
                      }
                    }}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <p className="m-0">{translation.cookieManageText}</p>
            </Col>
          </Row>
        </div>
      )}
    </LanguageContext.Consumer>
  );
};

const mapStateToProps = state => ({
  cookiebox: getCookiebox(state)
});

export default connect(
  mapStateToProps,
  actions
)(ManageCookie);
