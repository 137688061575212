import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
// import {Link} from 'gatsby'

import AgendaHomeForm from './../Form/AgendaHomeForm'
import AgendaListItem from '../List/AgendaListItem'

import * as loadingActions from '../../../state/actions/loading'

import Config from '../../../config/Config.json'
import Config_local from '../../../config/Config_local.json'
import '../../../config/Moment.js'

const agendaTileWidth = () => {
	if(typeof window !== 'undefined'){
		switch(true){
			case window.innerWidth < 768:
				return 1
			case window.innerWidth < 1200:
				return 2
			default:
				return 4
		}
	}
	return 0
}

const config = {...Config, ...Config_local}

class AgendaHome extends Component {
	_isMounted = false

	constructor(props, context) {
		super(props, context)

		this.startDate = new Date();
		this.endDate = new Date(2222, 12, 31, 0, 0, 0);

		// Liste des filtres actifs
		this.state = {
			totals: false,
			offset: false,
			limit: false,
			posts: 0,
			startDate: moment(this.startDate.toISOString()),
			endDate: moment(this.endDate.toISOString()),
			current : '',
			currentPosition : 0,
			nbrSlide : agendaTileWidth(),
			changeArrow : false,
		}
	}

	componentDidMount(){
		this._isMounted = true
		const {loadingOn} = this.props

		typeof window !== 'undefined' && window.addEventListener('resize', this.agendaUpdateWindowsWidth)
		this.launchSearch()
		loadingOn('AgendaHome')
	}

	componentWillUnmount() {
		const {loadingOff} = this.props

		typeof window !== 'undefined' && window.removeEventListener('resize', this.agendaUpdateWindowsWidth)
		loadingOff('AgendaHome')
		this._isMounted = false
	}

	componentDidUpdate(prevProps, prevState) {
		(prevState.startDate.unix() !== this.state.startDate.unix() || prevState.endDate.unix() !== this.state.endDate.unix()) && this.launchSearch()
	}

	agendaUpdateWindowsWidth = () => {
		this.setState({nbrSlide: agendaTileWidth()});
	}


	agendaOnClickDatePickerThisDay = (e) => {
		e.preventDefault()

		const {loadingOn} = this.props

		this.dateNow = new Date();

		this.setState({
			startDate: moment(this.dateNow.toISOString()),
			endDate: moment(this.dateNow.toISOString()),
			page: 0,
			current : 'day'
		})

		loadingOn('AgendaHome')
	}

	agendaOnClickDatePickerThisWeek = (e) => {
		e.preventDefault()

		const {loadingOn} = this.props

		this.dateNow = new Date();

		this.setState({
			startDate: moment(this.dateNow.toISOString()),
			endDate: moment(this.dateNow.toISOString()).startOf('week').isoWeekday(7),
			page: 0,
			current : 'week'
		})

		loadingOn('AgendaHome')
	}

	agendaOnClickDatePickerThisWeekend = (e) => {
		e.preventDefault()

		const {loadingOn} = this.props

		this.dateNow = new Date();

		this.setState({
			startDate: moment(this.dateNow.toISOString()).startOf('week').isoWeekday(6),
			endDate: moment(this.dateNow.toISOString()).startOf('week').isoWeekday(7),
			page: 0,
			current : 'weekend'
		})

		loadingOn('AgendaHome')
	}

	agendaSlidePrev = (e) => {
		e.preventDefault()
		const {currentPosition, nbrSlide} = this.state

		this.setState({
			currentPosition: nbrSlide * (currentPosition - 1) < 0 ? nbrSlide * (currentPosition - 1) : currentPosition - 1,
			changeArrow: false
		})
	}

	agendaSlideNext = (e) => {
		e.preventDefault()
		const {currentPosition, nbrSlide} = this.state
		const newPosition = nbrSlide * (currentPosition + 1) >= 12 ? 0 : currentPosition + 1
		const nextPosition = nbrSlide * (currentPosition + 2) >= 12 ? 0 : currentPosition + 2

		this.setState({
			currentPosition: newPosition,
			changeArrow: (newPosition > 0 && nextPosition === 0 ) ? true : false
		})
	}

	launchSearch() {
		const {startDate, endDate} = this.state
		const {loadingOff} = this.props

		if(startDate.isValid() || endDate.isValid()){
			let conf = {...config, openAgendaFluxFilters: ""}

			conf.openAgendaFluxFilters = startDate.isValid() ? `?eventStart=${startDate.format('MM/DD/YYYY')}` : ""

			endDate.isValid() && conf.openAgendaFluxFilters.length ?
				conf.openAgendaFluxFilters += `&eventStop=${endDate.format('MM/DD/YYYY')}` :
				conf.openAgendaFluxFilters = `?eventStop=${endDate.format('MM/DD/YYYY')}`

			fetch(`${conf.openAgendaFlux}${conf.openAgendaFluxFilters}&limit=12`,
				{
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					method: "POST"
				}
			).then(response => {
				if(response.status && response.status > 200){
					console.warn(response)
				}else{
					return response.json()
				}
			}).then(results => {
				const {total, offset, limit, events} = results
				this._isMounted && this.setState({
					totals: total,
					offset,
					limit,
					posts: events
				})

				loadingOff('AgendaHome')
			}).catch(error => console.error("error", error))
		}
	}

	render(){
		const {posts, current, currentPosition, nbrSlide} = this.state

		return (
			<div id="agenda" className="agenda-home">
				<AgendaHomeForm
					current={current}
					OnClickDatePickerThisDay={this.agendaOnClickDatePickerThisDay}
					OnClickDatePickerThisWeek={this.agendaOnClickDatePickerThisWeek}
					OnClickDatePickerThisWeekend={this.agendaOnClickDatePickerThisWeekend}
				/>

				{posts.length > 0 && <span className="agenda-list-home-prev" onClick={this.agendaSlidePrev} onKeyPress={this.agendaSlidePrev} role="button" tabIndex={0}>&nbsp;</span>}
				<div id="agenda" className="agenda-home-content">
					<ul id="agenda-list" className="list-unstyled agenda-list agenda-list-home" style={{transform: `translate3d(-${300 * currentPosition * nbrSlide}px, 0, 0)`}}>
						{posts.length > 0 && posts.map((post, index) =>
							<li className="agenda-list-item agenda-list-home-item" key={index}>
								<AgendaListItem
									postIndex={index}
									postItem={post}
								/>
							</li>
						)}
						{!posts.length > 0 && Array(5).fill('').map((u, index) => <li className="agenda-list-item agenda-list-home-item" key={index} />)}
					</ul>
				</div>
				{posts.length > 0 && <span className={ this.state.changeArrow ? "agenda-list-home-next agenda-list-home-next-gostart" : "agenda-list-home-next" } onClick={this.agendaSlideNext} onKeyPress={this.agendaSlideNext} role="button" tabIndex={0}>&nbsp;</span>}
				{/*<div className="agenda-covid agenda-covid-home">
					<p className="section-title"><span>Que faire à la maison&nbsp;?</span></p>
					<Link className="banner-button btn btn-secondary" to="/que-faire-a-la-maison/">Pour divertir & cultiver petits et grands... C'est par ici&nbsp;!</Link>
				</div>*/}
			</div>
		)
	}
}

export default connect(null, loadingActions)(AgendaHome)
