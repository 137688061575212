import React from 'react'
import { FormGroup } from 'reactstrap'
import { Label } from 'reactstrap'
import { AvGroup, AvInput } from 'availity-reactstrap-validation'

const ConsentField = ({field}) => {
    return(
        <FormGroup className="col-12">
            <AvGroup className={`textfield ${field.cssClass}`}>
                <AvInput 
                    name={field.inputs[0].fieldId} 
                    id={field.inputs[0].fieldId} 
                    required={field.isRequired} type='checkbox' 
                    trueValue={field.choices[0].value}
                    falseValue=""
                    value={field.choices[0].value}
                />
                <Label for={field.inputs[0].fieldId}>{field.checkboxLabel + (field.isRequired ? ' *' : '')}</Label>
                {field.description && <p className="field-description mt-1 mb-0">{field.description}</p>}
            </AvGroup>
            {field.description && <p className="field-description mt-1 mb-0">{field.description}</p>}
        </FormGroup>
    )
}

export default ConsentField
