import React from 'react'
import Img from 'gatsby-image'

const Information = ({attrs, images}) => {
	const image = attrs && attrs.mediaId && images && images.find(({wordpress_id}) => wordpress_id === attrs.mediaId)
	const imageSharpSizes = image && image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.sizes

	return(
		<div className="block-information">
			<div className="block-information-title-n-thumb">
				{attrs.title && <div className="block-information-title" dangerouslySetInnerHTML={{__html: attrs.title}} />}
				<div className="block-information-thumb">
					{imageSharpSizes ? <Img sizes={imageSharpSizes} /> : <div className="block-information-thumb-fake"></div>}
				</div>
			</div>
			{attrs.informationContent && <div className="block-information-content" dangerouslySetInnerHTML={{__html: attrs.informationContent}} />}
		</div>
	)
}

export default Information
