import React from 'react'
import Img from 'gatsby-image'

const Image = ({attrs, images}) => {
	const image = attrs && attrs.imageid && images && images.find(({wordpress_id}) => wordpress_id === attrs.imageid)
	const imageAlt = attrs && attrs.image_alt
	const imageAhref = attrs && attrs.image_ahref
	const imageAtarget = attrs && attrs.image_atarget
	const imageCaption = attrs && attrs.image_caption
	
	if ( imageAhref ) {
		return (
			<a href={imageAhref} target={imageAtarget}>
				{ image && image.localFile && image.localFile.childImageSharp ? <Img alt={imageAlt} sizes={image.localFile.childImageSharp.sizes}/> : <p>Image corrompue.</p> }
				{ imageCaption ? <p className='image-caption' dangerouslySetInnerHTML={{__html: imageCaption}}></p> : '' }
			</a>
		)
	} else {
		return (
			<>
				{ image && image.localFile && image.localFile.childImageSharp ? <Img alt={imageAlt} sizes={image.localFile.childImageSharp.sizes}/> : <p>Image corrompue.</p> }
				{ imageCaption ? <p className='image-caption' dangerouslySetInnerHTML={{__html: imageCaption}}></p> : '' }
			</>
		)
	}
}

export default Image
