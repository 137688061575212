import React from 'react'

import NewsItemDate from './Items/NewsItemDate'
import NewsItemTitle from './Items/NewsItemTitle'

const NewsListItem = ({postIndex, postItem}) =>
	<>
		{postItem && postItem._source && postItem._source.permalink &&
			<a key={postIndex} className={postItem._source.post_type ? `news-list-item-content news-list-item-${postItem._source.post_type}` : 'news-list-item'} target="_blank" rel="noopener noreferrer" href={postItem._source.permalink}>
				<div className="news-list-item-head">
					{postItem._source.post_image &&
						<p className="news-list-item-picture">
							<img
								src={postItem._source.post_image}
								alt={((postItem.highlight && postItem.highlight.post_title) || postItem._source.post_title).replace(/(<([^>]+)>)/ig, '')}
							/>
						</p>
					}
				</div>
				<div className="news-list-item-infos">
					<NewsItemTitle postItem={postItem} />
					<NewsItemDate postItem={postItem} />
				</div>
			</a>
		}
	</>

export default NewsListItem
