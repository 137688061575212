import React from 'react'
import { FormGroup } from 'reactstrap'
import { AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation'

const CheckField = ({field}) => {
    return(
        <FormGroup className="col-12">
            <AvCheckboxGroup 
                className="checkgroup"
                inline 
                name={field.gravityId} 
                label={field.label + (field.isRequired ? ' *' : '')} 
                required={field.isRequired} 
                errorMessage={field.errorMessage !== "" ? field.errorMessage : "Champs non valide"}>
                {
                    field.inputs.map(({fieldId, trueValue, label}) => 
                        <AvCheckbox
                            key={fieldId} 
                            label={label}
                            value={JSON.stringify({fieldId, value: trueValue})}
                        />
                    )
                }
            </AvCheckboxGroup>
            {field.description && <p className="field-description mt-1 mb-0">{field.description}</p>}
        </FormGroup>
    )
}

export default CheckField
