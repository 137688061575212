import React from 'react'
import { Label } from 'reactstrap'
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'

const InputField = ({field, size}) => {
    return(
        <AvGroup className={`textfield ${size} ${field.cssClass}`}>
            <Label for={field.gravityId}>{field.label + (field.isRequired ? ' *' : '')}</Label>
            <AvInput name={field.gravityId} id={field.gravityId} required={field.isRequired} type={field.type === 'tel' ? 'text' : field.type} />
            <AvFeedback>{field.errorMessage !== "" ? field.errorMessage : "Champs non valide"}</AvFeedback>
            {field.description && <p className="field-description mt-1 mb-0">{field.description}</p>}
        </AvGroup>
    )
}

export default InputField
