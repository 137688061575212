import React from 'react'
import Img from 'gatsby-image'
import separator from '../../../img/separateur-2.svg'

const Contact = ({attrs, images}) => {
	const image = attrs && attrs.mediaId && images && images.find(({wordpress_id}) => wordpress_id === attrs.mediaId)

	return(
		<div className="block-contact">
			<div className="block-contact-thumb">
				{image && image.localFile && image.localFile.childImageSharp ? <Img sizes={image.localFile.childImageSharp.sizes}/> : '' }
			</div>
			<div className="block-contact-person">
				<div className="block-contact-person-title" dangerouslySetInnerHTML={{__html: attrs.title}} />
				<div className="block-contact-person-subtitle" dangerouslySetInnerHTML={{__html: attrs.subtitle}} />
				<img src={separator} alt="" />
			</div>
			<div className="block-contact-description" dangerouslySetInnerHTML={{__html: attrs.contactContent}} />
		</div>
	)
}

export default Contact
