import React from 'react'
import NewsList from "./NewsList"

const NewsHome = ({match}) => (
	<div className="news-home">
		<NewsList where="home" />
	</div>
)

export default NewsHome
