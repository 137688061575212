import React from 'react'
import { Link } from 'gatsby'
import NewsHome from '../../News/NewsHome'
import { Container, Row, Col } from 'reactstrap'

import { addresses } from '../../../utils/variables'

const HomeActus = () => 
	<div className="home-slice home-slice-news">
		<Container>
			<Row>
				<Col sm="12" lg="6">
					<p className="section-title">
						<span>Les actualités</span> à la une
					</p>
				</Col>
				<Col sm="12" lg="6">
					<p className="section-link text-lg-right mt-1 mt-lg-4">
						<a className="news-home-all-link" target="_blank" rel="noopener noreferrer" href={addresses['roubaixxl']}>Voir plus d’actualités sur roubaixxl.fr</a>
					</p>
				</Col>
			</Row>
			<NewsHome />
			<p className='news-list-item-external' ><Link to='/toutes-les-publications/' className='news-list-item-external-link' >Votre magazine ROUBAIXXL en ligne : tous les numéros en un clic</Link></p>
		</Container>
	</div>

export default HomeActus
