import React from "react";
import parse from "html-react-parser";

import Image from "./Image";
import Columns from "./Columns";

const switchBlocks = {
  "core/image": Image,
  "core/columns": Columns
};

const Core = ({ blockName, innerHTML, ...rest }) => (
  <>
    {blockName in switchBlocks
      ? switchBlocks[blockName](rest)
      : parse(`<div>${innerHTML}</div>`)}
  </>
);

export default Core;
