import React from 'react'

const NewsItemTitle = ({postItem, postItemHome}) =>
	<>
		{postItem.highlight && postItem.highlight.post_title &&
			<h2
				className={`news-list-item-title ${postItemHome ? 'news-list-home-item-title': ''}`}
				dangerouslySetInnerHTML={{ __html: postItem.highlight.post_title }}
			></h2>
		}
		{postItem._source && postItem._source.post_title &&
			<h2
				className={`news-list-item-title ${postItemHome ? 'news-list-home-item-title': ''}`}
				dangerouslySetInnerHTML={{ __html: postItem._source.post_title }}
			></h2>
		}
	</>

export default NewsItemTitle