import React from 'react'
import { Col } from 'reactstrap'
import InputField from "./InputField";

const AddressField = ({field, size}) => {
    return(
        <Col xs="12 my-4 mx-0 px-0">
            <p className="form-section-title mb-0 px-3">{field.label + (field.isRequired ? ' *' : '')}</p>
            <Col className="row">
                {field.inputs && field.inputs.map((input, index) => {
                    const field = {
                        'label': input.label,
                        'gravityId': input.fieldId,
                        'cssClass': 'col-6 col-md-6  form-group',
                        'isRequired': true,
                        'type': 'text',
                        'errorMessage': '',
                        'description': '',
                    }
                    return (
                        <InputField key={index} field={field} size="col-6 col-md-6" />
                    )
                })}
            </Col>
        </Col>
    )
}

export default AddressField
