import React from 'react'
import downloadIcon from '../../../img/icons/download.png'
import defaultThumb from '../../../img/icons/document.png'

const Download = ({attrs, images}) => {
	const image = attrs && attrs.mediaId && images.find(({wordpress_id}) => wordpress_id === attrs.mediaId)
	const imageSharpSizes = image && image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.sizes
	const imageBackground = {backgroundImage: `url(${imageSharpSizes ? imageSharpSizes.src : defaultThumb})`}
	const imageClassName = `block-download-thumb${imageSharpSizes ? ' block-download-thumb-default' : ''}`

	return(
		<div className="block-download">
			<a href={attrs.fileUrl ? attrs.fileUrl : "#"} target="_blank" rel="noopener noreferrer" title="Télécharger le document (ouvre un nouvel onglet)">
				<div className={imageClassName} style={imageBackground}></div>
				<div className="block-download-text" dangerouslySetInnerHTML={{__html: attrs.downloadContent}}></div>
				<div className="block-download-button">
					<img className="block-download-button-img" src={downloadIcon} alt=""/>
				</div>
			</a>
		</div>
	)
}

export default Download
