import React from 'react'
import { Label, Input } from 'reactstrap'
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'

class UploadField extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            file: null
        }
    }

    render(){
        const {field, size} = this.props

        return(
            <AvGroup className={`textfield ${size} ${field.cssClass}`}>
                <Label for={field.gravityId}>{field.label + (field.isRequired ? ' *' : '')}</Label>
                <Label className="custom-upload-field" for={field.gravityId}>
                    <div className="custom-upload-field__container">
                        <div className="custom-upload-field__value">{this.state && this.state.file && this.state.file.name ? this.state.file.name : ""}</div>
                        <div className="custom-upload-field__button">Parcourir</div>
                    </div>
                </Label>
                <Input 
                    className="d-none"
                    name={field.gravityId} 
                    id={field.gravityId}
                    type="file"
                    onChange={(event) => this.setState({file: event.target.files[0]})}
                    accept={".pdf, .jpg, .jpeg, .doc, .docx, application/pdf, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
                />
                <AvInput 
                    name={field.gravityId} 
                    className="d-none" 
                    value={this.state.file} 
                    required={field.isRequired} 
                />
                <AvFeedback>{field.errorMessage !== "" ? field.errorMessage : "Champs non valide"}</AvFeedback>
                {field.description && <p className="field-description mt-1 mb-0">{field.description}</p>}
            </AvGroup>
        )
    }
}

export default UploadField