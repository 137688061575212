import React, { Component } from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col, Button } from 'reactstrap'
import Slider from "react-slick"


import HomeTile from './HomeTile'



const tilesWidth = () => {
	if(typeof window !== 'undefined'){
		switch(true){
			case window.innerWidth < 768:
			 	return {nbrSlide: 2}
			case window.innerWidth <  1440:
				return {nbrSlide: 4}
			default:
				return {nbrSlide: 4}
		}
	}
	return {nbrSlide: 4, tileWidth: 241}
}


class HomeTiles extends Component{
	constructor(props, context){
		super(props, context)
		this.state = {
			...tilesWidth()		
		}
	}	

	componentDidMount(){
		typeof window !== 'undefined' && window.addEventListener('resize', this.tilesUpdateWindowsWidth)
	}

	componentWillUnmount(){
		typeof window !== 'undefined' && window.removeEventListener('resize', this.tilesUpdateWindowsWidth)
	}

	tilesUpdateWindowsWidth = () => {
		this.setState(tilesWidth())
	}

	render(){
		const { attrs, images } = this.props

		const settings = {
			dots: false,
			infinite: false,
			slidesToScroll: this.state.nbrSlide,
			slidesToShow: this.state.nbrSlide,
			variableWidth: true
		}

		return (
			<div className="home-services-section bg-light py-5">
				<Container>
					<Row>
						<Col sm="12" lg="6">
							<p className="section-title"><span>Les services</span> et infos pratiques</p>
						</Col>
						<Col sm="12" lg="6">
							<p className="section-link text-lg-right mt-1 mt-lg-4"><Link className="tiles-home-all-link" to="/services-infos-pratiques/">Voir tous les services et infos pratiques</Link></p>
						</Col>
					</Row>
					<div className="hometiles slideshow-wrapper">
						<div className={`slideshow${attrs ? '' : ' placeholder'}`}>
							<Button className="custom-slick-prev" onClick={() => this.slider.slickGoTo(0)}/>
							<Slider ref={slider => (this.slider = slider)} {...settings}>
								{attrs ?
									attrs.items.map((i, index) => <HomeTile key={index} images={images} {...i} />)
									:
									Array.from(Array(6)).map((i, index) => <HomeTile key={index} placeholder />)
								}
							</Slider>
						</div>
					</div>
				</Container>
			</div>
		)
	}
}


export default HomeTiles
