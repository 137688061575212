import React from 'react'
import { Container } from 'reactstrap'

import SearchHome from '../../Search/SearchHome'

const SearchSection = () => 
	<div className="home-slice home-slice-search">
		<Container>
			<SearchHome SearchTitle="Comment pouvons-nous vous aider ?"/>
		</Container>
	</div>

export default SearchSection
