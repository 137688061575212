import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { AvForm, AvGroup, AvInput } from "availity-reactstrap-validation";

import { Button, FormGroup, Row } from "reactstrap";
import axios from "axios";

import Config_local from "./../../../../config/Config_local";

import InputField from "./components/InputField";
import RadioField from "./components/RadioField";
import SelectField from "./components/SelectField";
import DateField from "./components/DateField";
import CheckField from "./components/CheckField";
import ConsentField from "./components/ConsentField";
import UploadField from "./components/UploadField";
import SectionField from "./components/SectionField";
import AddressField from "./components/AddressField";

const axiosConfig = {
  method: "post",
  responseType: "json",
  responseEncoding: "utf8",
  mode: "no-cors",
  headers: {
    "Content-Type": "multipart/form-data"
  }
};

const switchField = {
  text: rest => <InputField {...rest} />,
  name: rest => <InputField {...rest} />,
  email: rest => <InputField {...rest} />,
  tel: rest => <InputField {...rest} />,
  checkbox: rest => <CheckField {...rest} />,
  consent: rest => <ConsentField {...rest} />,
  radio: rest => <RadioField {...rest} />,
  select: rest => <SelectField {...rest} />,
  date: rest => <DateField {...rest} />,
  fileupload: rest => <UploadField {...rest} />,
  textarea: rest => <InputField {...rest} />,
  section: rest => <SectionField {...rest} />,
  address: rest => <AddressField {...rest} />
};

const switchSize = {
  small: "col-12 col-md-3",
  medium: "col-12 col-md-6",
  large: "col-12"
};

class CustomForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
			validCaptcha : false,
			valueCaptcha : null,
      submitted: false,
      error: false,
      loading: false
    };
  }

  validSubmit = (event, values) => {
    if ( this.state.validCaptcha ) {
      this.setState({
        loading: true,
        submitted: true
      });
      const formData = new FormData();
      const arrayValues = Object.keys(values).map(function(key) {
        return [key, values[key]];
      });

      arrayValues.map(keyValue => {
        const index = keyValue[0],
          value = keyValue[1];
        if (Array.isArray(value))
          value.map(valueString => {
            const valueObject = JSON.parse(valueString);
            formData.append(valueObject.fieldId, valueObject.value);
            return null;
          });
        else formData.append(index, value);
        return null;
      });

      const self = this;
      const postConfig = {
        ...axiosConfig,
        ...{
          data: formData,
          url: Config_local.apiServer + this.props.attrs.form_url + "/submissions"
        }
      };
      axios(postConfig)
        .then(({ data }) => {
          if (data.is_valid) self.setState({ error: false, loading: false });
          else throw new Error("error");
        })
        .catch(() => {
          self.setState({ error: true, loading: false });
        });
    }
  }

  invalidSubmit = (event, errors, values) => {
    this.setState({ error: true, submitted: false, loading: false });
  }

  resetForm = () => {
    this.setState({ error: false, submitted: false, loading: false });
  }

	onCaptchaChange = (value) => {
		if ( value !== null ) {
      this.setState({ validCaptcha: true, valueCaptcha: value})
    } else { 
      this.setState({ validCaptcha: false})
    }
	}

  render() {
    const { attrs } = this.props;
    const { submitted, error, loading, valueCaptcha } = this.state;

    if (submitted && !error && !loading) {
      return (
        <div className="form-container submitted valid">
          <p>
            {attrs.defaultConfirmation && attrs.defaultConfirmation.message
              ? attrs.defaultConfirmation.message
              : "Le formulaire a été envoyé"}
          </p>
          <button className="btn btn-secondary" onClick={this.resetForm}>
            Retour au formulaire
          </button>
        </div>
      );
    } else if (submitted && error && !loading) {
      return (
        <div className="form-container submitted error">
          <p>Erreur lors de l'envoi du formulaire</p>
          <button className="btn btn-secondary" onClick={this.resetForm}>
            Retour au formulaire
          </button>
        </div>
      );
    }
    return (
      <div className={`form-container ${loading ? "is-loading" : ""}`}>
        {loading && <div className="loading" />}
        <AvForm
          onValidSubmit={this.validSubmit}
          onInvalidSubmit={this.invalidSubmit}
        >
          <Row>
            {attrs.wordpress_fields.map(
              f =>
                f.type in switchField &&
                switchField[f.type]({
                  key: f.gravityId,
                  field: f,
                  size: switchSize[f.size]
                })
            )}
          </Row>

          <Row>
            <FormGroup className="captcha-container col-12 col-sm-6">
              <legend>Veuillez cocher la case ci-dessous *</legend>
              <ReCAPTCHA
                sitekey={Config_local.recaptchaKey}
                onChange={this.onCaptchaChange}
                type="image"
              />
              <AvGroup className={`textfield col-12 col-sm-6 textfieldsp`}>
                  <AvInput name="input_100" id="recaptchValue" required="true" type="text" value={valueCaptcha}/>
              </AvGroup>
            </FormGroup>
            <FormGroup className="col-12 col-sm-6 mt-5 mt-md-0 d-flex flex-column justify-content-end">
              <div className="text-center text-sm-right">
                <Button
                  color="primary"
                  className={`submit-button btn btn-primary px-4`}
                  disabled={loading}
                >
                  {attrs.button.text ? attrs.button.text : "Envoyer"}
                </Button>
              </div>
            </FormGroup>
          </Row>
        </AvForm>
      </div>
    );
  }
}

export default CustomForm;
