import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import AgendaFormFilterDatesLink from './AgendaFormFilterDatesLink'

const AgendaHomeForm = ({current, OnClickDatePickerThisDay, OnClickDatePickerThisWeek, OnClickDatePickerThisWeekend}) =>
	<form id="agenda-filters" className="agenda-filters agenda-filters-home" >
		<Container>
			<Row>
				<Col md="12" lg="3">
					<h1 className="section-title mb-2 mt-lg-0"><span>Sortir</span> à Roubaix</h1>
				</Col>

				<Col className="d-block d-lg-none mb-3 ">
					<p className="section-link"><Link className="agenda-filters-header-all-link" to='/agenda/' >Voir l'agenda complet</Link></p>
				</Col>

				<Col md="12" lg="6">
					<AgendaFormFilterDatesLink 
					location="home"
				/>
				</Col>

				<Col className="d-none d-lg-block" lg="3">
					<p className="section-link text-right"><Link className="agenda-filters-header-all-link" to='/agenda/' >Voir l'agenda complet</Link></p>
				</Col>
			</Row>
		</Container>
	</form>

export default AgendaHomeForm
