import React from 'react'
import { FormGroup } from 'reactstrap'
import { AvRadioGroup, AvRadio } from 'availity-reactstrap-validation'



const RadioField = ({field}) => {
    return(
        <FormGroup className="col-12">
            <AvRadioGroup 
                className="radiogroup"
                inline
                name={field.gravityId} 
                label={field.label + (field.isRequired ? ' *' : '')} 
                required={field.isRequired} 
                errorMessage={field.errorMessage !== "" ? field.errorMessage : "Champs non valide"}>
                {
                    field.choices.map(({value, text}) => 
                        <AvRadio
                            key={value} 
                            label={text} 
                            value={value}
                        />
                    )
                }
            </AvRadioGroup>
            {field.description && <p className="field-description mt-1 mb-0">{field.description}</p>}
        </FormGroup>
    )
}


export default RadioField
