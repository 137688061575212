import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { Container, Button } from 'reactstrap'

const isValidUrl = (string) => {
	try {
		if(string.indexOf("http://") === 0 || string.indexOf("https://") === 0){
			return true
		}
	}
	catch{
		return false
	}
	return false
}

const HomeBannerLink = ( { link , children} ) => {
	if(isValidUrl(link)) return(
		<a href={link} className="d-block block-homebanner">
			{ children }
		</a>
	)

	return(
		<Link to={link} className="d-block block-homebanner">
			{ children }
		</Link>	
	)
}

const HomeBanner = ({attrs, images}) => {
	const image = attrs && attrs.mediaId && images.find(({wordpress_id}) => wordpress_id === attrs.mediaId)
	const imageSharpSizes = image && image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.sizes

	const imageMobile = attrs && attrs.mediaMobileId && images.find(({wordpress_id}) => wordpress_id === attrs.mediaMobileId)
	const imageMobileSharpSizes = imageMobile && imageMobile.localFile && imageMobile.localFile.childImageSharp && imageMobile && imageMobile.localFile && imageMobile.localFile.childImageSharp.sizes

	return(
		<>
			{attrs ?
				<HomeBannerLink link={attrs.link}>
					<div className="content-wrapper">
						<Container className="h-100 position-relative content-container">
							<div className="content">
								{ attrs.category && <span className="category">{attrs.category}</span> }
								<h1 className="title">{attrs.title}
									{ attrs.subtitle && <span className="subtitle">{attrs.subtitle}</span> }
								</h1>
								{ attrs.buttonlabel && <Button className="banner-button">{ attrs.buttonlabel }</Button> }	
							</div>
						</Container>
					</div>

					<div className="d-none d-sm-block background-image">
						{imageSharpSizes && <Img sizes={imageSharpSizes} />}
					</div>

					<div className="d-block d-sm-none background-image">
						{imageMobileSharpSizes && <Img sizes={imageMobileSharpSizes} />}
					</div>
				</HomeBannerLink>
				:
				<div className="block-homebanner">
					<div className="background-image placeholder">
						<div className="placeholder-image" />
					</div>
				</div>
			}
		</>
	)
}

export default HomeBanner
