import React from 'react'
import v4 from 'uuid/v4'

const ListInfo = ({attrs}) =>
	<div className="block-listinfo">
		{attrs && attrs.listinfoContent && attrs.listinfoContent.map(({title, info, description}) =>
			<div className="element" key={v4()}>
				<p className="title">{title}</p><div className="info" dangerouslySetInnerHTML={{__html: info}}/>
				{description &&
					<div className="description" dangerouslySetInnerHTML={{__html: description}}/>
				}

			</div>
		)}
	</div>

export default ListInfo
