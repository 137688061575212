import React from 'react'
import AgendaHome from '../../Agenda/Home/AgendaHome'
import { Container } from 'reactstrap'

const HomeAgenda = () => 
	<div className="home-slice home-slice-agenda">
		<Container>
			<AgendaHome />
		</Container>
	</div>

export default HomeAgenda
