import React, { Component } from 'react'
import Img from 'gatsby-image'

class Collapse extends Component{
	constructor(props){
		super(props)

		this.state = {
			isOpen: false
		}
	}

	handleChange = (props) => {
		this.setState(state => ({isOpen: !state.isOpen}))
	}

	render(){
		const {attrs, images} = this.props
		const image = attrs && attrs.mediaId && images.find(({wordpress_id}) => wordpress_id === attrs.mediaId)
		const imageSharpSizes = (image && image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.sizes) || false
		
		return(
			<div className={`block-collapse${this.state.isOpen ? ' block-collapse-opened' : ''}`}>
				<div onClick={this.handleChange} onKeyPress={this.handleChange} role="button" tabIndex={0}>
					<div className="block-collapse-title" dangerouslySetInnerHTML={{__html: attrs.title}} /><span className="d-none">&nbsp;</span>
				</div>
				<div className="block-collapse-content">
					<div dangerouslySetInnerHTML={{__html: attrs.collapseContent}} />
					{imageSharpSizes && <Img sizes={imageSharpSizes}/> }
				</div>
			</div>
		)
	}
}

export default Collapse
