import React from "react";
import { Col } from "reactstrap";

const SectionField = ({ field }) => {
  return (
    <Col xs="12 my-4">
      <p className="form-section-title mb-0">{field.label}</p>
      {field.description && (
        <p className="form-section-description mt-0 mb-0">
          {field.description}
        </p>
      )}
      <div className="border-bottom mt-3" />
    </Col>
  );
};

export default SectionField;
