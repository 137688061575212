import React from 'react'
import {Link} from 'gatsby'

const AgendaFormFilterDatesLink = ({location}) =>
	<div className={`agenda-filters-dates-buttons${location === 'home' ? ' agenda-filters-home-dates-buttons' : ''}`}>
		<Link className={`agenda-filters-dates-day${location === 'home' ? ' agenda-filters-home-dates-day' : ''}`} to="/agenda/dates/aujourd-hui">
			Aujourd'hui
		</Link>
		<Link className={`agenda-filters-dates-week${location === 'home' ? ' agenda-filters-home-dates-week' : ''}`} to="/agenda/dates/cette-semaine">
			Cette semaine
		</Link>
		<Link className={`agenda-filters-dates-weekend${location === 'home' ? ' agenda-filters-home-dates-weekend' : ''}`} to="/agenda/dates/ce-weekend">
			Ce week-end
		</Link>
	</div>

export default AgendaFormFilterDatesLink
