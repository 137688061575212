import React, { Component } from 'react'
import DatePicker from 'react-date-picker'
import { Label } from 'reactstrap'
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation'

//const month_names = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
const dateToString = (date) => date ? (date.getDate() + "/" + (date.getMonth() + 1) + "/" +  date.getFullYear()) : ""

class DateField extends Component{
    constructor(props){
        super(props)
        this.state = {
            date: null,
            stringDate: ""
        }
    }

    render(){
        const {field, size} = this.props
        const {date, stringDate} = this.state

        return(
            <AvGroup className={`datefield ${size} ${field.cssClass}`}>
                <Label for={field.gravityId}>{field.label + (field.isRequired ? ' *' : '')}</Label>
                <DatePicker
                    onChange={(date) => this.setState({
                        date,
                        stringDate: dateToString(date)
                    })}
                    value={date}
                    showLeadingZeros={true}
                    calendarClassName={"calendar"}
                    clearIcon={null}
                    locale={"fr"}
                    showNeighboringMonth={false}
                    showWeekNumbers={false}
                    //formatMonthYear= {(locale, date) => month_names[date.getMonth()]}
                />
                <AvInput 
                    name={field.gravityId} 
                    id={field.gravityId} 
                    required={field.isRequired} 
                    type="text"
                    value={stringDate}
                    className="d-none"
                />
                <AvFeedback>{field.errorMessage !== "" ? field.errorMessage : "Champs non valide"}</AvFeedback>
                {field.description && <p className="field-description mt-1 mb-0">{field.description}</p>}
            </AvGroup>
        )
    }

}

export default DateField
