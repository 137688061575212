import React, { Component } from 'react'
import { connect } from 'react-redux'

import * as loadingActions from '../../state/actions/loading'

import Config from '../../config/Config.json'
import Config_local from '../../config/Config_local.json'
import NewsQueryConfig from './NewsQueryConfig.json'
import NewsListItem from "./NewsListItem"


const config = {...Config, ...Config_local}

const newsTileWidth = () => {
	if(typeof window !== 'undefined'){
		switch(true){
			case window.innerWidth <  1200:
				return 2
			case window.innerWidth <  768:
				return 1
			default:
				return 4
		}
	}
	return 0
}

class NewsList extends Component {
	_isMounted = false

	constructor(props, context) {
		super(props, context)

		//On définit les variables à surveiller
		this.state = {
			totals: false,
			page: 0,
			launchNews: true,
			posts: 0,
			currentPosition: 0,
			nbrSlide: newsTileWidth(),
			changeArrow: false
		}
	}

	componentDidMount(){
		this._isMounted = true
		const {loadingOn, where} = this.props
		
		where === 'home' && typeof window !== 'undefined' && window.addEventListener('resize', this.newsUpdateWindowsWidth)
		this.launchNews()
		loadingOn('NewsHome')
	}

	componentWillUnmount() {
		const {loadingOff, where} = this.props

		where === "home" && typeof window !== 'undefined' && window.removeEventListener('resize', this.newsUpdateWindowsWidth)
		loadingOff('NewsHome')
		this._isMounted = false
	}

	newsUpdateWindowsWidth = () => {
		this.setState({nbrSlide: newsTileWidth()})
	}

	launchNews() {
		const {loadingOff} = this.props
		const body = JSON.stringify(NewsQueryConfig.queryDefault)

		fetch(config.esServer,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					tokenesinouit: 'spS4brB5EYX2tu8exUPFZDXvAr0ZD7u9whzxPfGqGvGWGcRMDqHxH04axVSj1k0a6TlJTAagi4Sz3yKg5NNPi0F7m2LUSFvZCCQYzMdTS3WXxAXtATBbWVhXwMpSS79PycfnwdabDVsSWttOAWkyCK67DWXq5g1HYTwH8toyF9HyZwrOmttRnYQSf4qnzUJJWYyTAAbvBrdegXFweIdt5IRX0S5GL8ARk3Kz30GWR3tQpxNwrmid9nSe7SQxopoVC7Kl9gR5zQY7ijpeohaEqJZsvg3hN400000iGm8nKB0vKL2i9nPFaLIzVXV6c4RKuWBwzXrBOiCfyAtjyZAe7dpEIuqz82cIh80wiRaVCdOQ9YtE355IqgpiOytsSKTtw6HuWaGjopqjRe9pzixWJWGhLRGPB9iZRe1TP78HuiPdWKHUGWxB5MHroMoTbFqfyLXo9kk4djQA2GuAsTLI8VYOCDwCAcfJOPMtJIwa356KHK8Ma4VCFYgWqJ2sZtT77pOszI8deVGb9M1DXWD9nPhsGJSqDt4K8TRzhcVmgn8sUamx8TFyPa1E06Gh2QUCyXTfjOP0LzSgAdb2gaOohuSOfYibjfoE3aCSnevQAflmGoCqsFphlYNCTzYQhdO2'
				},
				method: "POST",
				body
			}
		).then(response => {
			if(response.status && response.status > 200 ){
				console.warn(response)
			}else{
				return response.json()
			}
		}).then(results => {
			const {total, hits} = results.hits
			this._isMounted && this.setState({
				totals: total,
				posts: hits
			})

			loadingOff('NewsHome')
		}).catch(function(error) {
			console.error("error", error);
		})
	}

	newsSlidePrev = (e) => {
		e.preventDefault()
		const {currentPosition, nbrSlide} = this.state

		this.setState({
			currentPosition: nbrSlide * (currentPosition - 1) < 0 ? nbrSlide * (currentPosition - 1) : currentPosition - 1,
			changeArrow: false
		})
	}

	newsSlideNext = (e) => {
		e.preventDefault()
		const {currentPosition, nbrSlide} = this.state
		const newPosition = nbrSlide * (currentPosition + 1) >= 12 ? 0 : currentPosition + 1
		const nextPosition = nbrSlide * (currentPosition + 2) >= 12 ? 0 : currentPosition + 2

		this.setState({
			currentPosition: newPosition,
			changeArrow: (newPosition > 0 && nextPosition === 0 ) ? true : false
		})
	}

	render(){
		const {posts, currentPosition, nbrSlide} = this.state
		const {where} = this.props


		return (
			<div id="news-list" className={where ? `news-list-${where}` : ''}>
				{where && posts.length > 0 && <span className="news-list-home-prev" onClick={this.newsSlidePrev} onKeyPress={this.newsSlidePrev} role="button" tabIndex={0}>&nbsp;</span>}
				<ul className="news-list list-unstyled" style={{transform: `translate3d(-${300 * currentPosition * nbrSlide}px, 0, 0)`}}>
					{posts.length > 0 && this.state.posts.map((post, index) =>
						<li className="news-list-item news-list-home-item" key={index}>
							<NewsListItem
								postIndex={index}
								postItem={post}
							/>
						</li>
					)}
					{!posts.length > 0 && Array(5).fill('').map((u, index) => <li className="news-list-item news-list-home-item" key={index} />)}
				</ul>
				{where && posts.length > 0 && <span className={ this.state.changeArrow ? "news-list-home-next news-list-home-next-gostart" : "news-list-home-next" } onClick={this.newsSlideNext} onKeyPress={this.newsSlideNext} role="button" tabIndex={0}>&nbsp;</span>}
			</div>
		)
	}
}

export default connect(null, loadingActions)(NewsList)
