import React from 'react'

import Anchor from './Anchor'
import Contact from './Contact'
import Foreground from './Foreground'
import Links from './Links'
import CustomForm from './CustomForm'
import Download from './Download'
import Heading from './Heading'
import Information from './Information'
import Collapse from './Collapse'
import HomeBanner from './HomeBanner'
import HomeAgenda from './HomeAgenda'
import HomeActus from './HomeActus'
import HomeTiles from './HomeTiles'
import SearchSection from './SearchSection'
import Galery from './Galery'
import ListInfo from './ListInfo'
import ManageCookie from './ManageCookie'


const switchBlocks = {
	"cgb/block-anchor": rest => <Anchor {...rest} />,
	"cgb/block-contact": rest => <Contact {...rest} />,
	"cgb/block-foreground": Foreground,
	"cgb/block-links": Links,
    "cgb/block-form": rest => <CustomForm {...rest} />,
	"cgb/block-download": rest => <Download {...rest} />,
	"cgb/block-heading": rest => <Heading {...rest} />,
	"cgb/block-information": rest => <Information {...rest} />,
	"cgb/block-collapse": rest => <Collapse {...rest} />,
	"cgb/block-homebanner": rest => <HomeBanner {...rest} />,
	"cgb/block-homeagenda": HomeAgenda,
	"cgb/block-hometiles": rest => <HomeTiles {...rest} />,
	"cgb/block-galery": rest => <Galery {...rest} />,
	"cgb/block-homeactus": HomeActus,
	"cgb/block-manage-cookies": rest => <ManageCookie {...rest} />,
	"cgb/block-searchsection": SearchSection,
	"cgb/block-listinfo": rest => <ListInfo {...rest} />,
	"cgb/block-page-description": () => <></>,

}

const Cgb = ({blockName, ...rest}) =>
	<>
		{blockName in switchBlocks ?
			switchBlocks[blockName](rest) : null
		}
		
		{/*
			<div className="bg-light border p-3 mb-1">
				<h2 className="text-danger">{blockName}</h2>
				{rest && rest.attrs &&
					Object.keys(rest.attrs).map(a => rest.attrs[a] && typeof rest.attrs[a] !== 'object' && <small key={v4()}><strong>{a}:</strong> {rest.attrs[a]} | </small>)
				}
			</div>
			*/}
	</>

export default Cgb
