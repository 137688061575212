import React from 'react'

const Heading = ({attrs}) => {
	return <div className={`block-heading text-${(attrs && attrs.alignment ? attrs.alignment : 'left')}`}>
			<div className="block-heading-content" dangerouslySetInnerHTML={{__html: attrs.headingContent}} />
		</div>
}
	

export default Heading
